import Vue from 'vue';
import Basic from './Basic.vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import VueSocketIO from 'vue-socket.io';
import VueCookies from 'vue-cookies';
import CountryFlag from 'vue-country-flag';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

Vue.use(Vuex)
Vue.config.productionTip = false

const store = new Vuex.Store({
  state: {
    watcherJwt: null
  }
})

function lazyLoad(view){
  return() => import(`./components/${view}.vue`)
}

const routes = [
    { path: '/date/*', name: 'dateSpec', component: lazyLoad('date/Date') },
    { path: '/date*', name: 'date', component: lazyLoad('date/Date') },
    { path: '/deathlist/*', name: 'deathlistSpec', component: lazyLoad('deathlist/Deathlist') },
    { path: '/deathlist*', name: 'deathlist', component: lazyLoad('deathlist/Deathlist') },
    { path: '/quiz/*', name: 'geoquizSpec', component: lazyLoad('quiz/Quiz') },
    { path: '/quiz*', name: 'geoquiz', component: lazyLoad('quiz/Quiz') },
    { path: '/jeopardy-host/*', name: 'jeopardyHostSpec', component: lazyLoad('jeopardy/JeopardyHost') },
    { path: '/jeopardy-host', name: 'jeopardyHost', component: lazyLoad('jeopardy/JeopardyHost') },
    { path: '/jeopardy-play/*', name: 'jeopardyPlay', component: lazyLoad('jeopardy/JeopardyPlay') },
    { path: '/qll/editor/*', name: 'QllEditor', component: lazyLoad('qll/QllEditor') },
    { path: '/qll/*', name: 'QllSpec', component: lazyLoad('qll/Qll') },
    { path: '/qll*', name: 'Qll', component: lazyLoad('qll/Qll') },
    { path: '/covid/*', name: 'CovidSpec', component: lazyLoad('covid/Covid') },
    { path: '/covid*', name: 'Covid', component: lazyLoad('covid/Covid') },
    { path: '/cov-travel*', name: 'CovidTravel', component: lazyLoad('covidTravel/CovidTravel') },
    { path: '/cov-travel/*', name: 'CovidTravelSpec', component: lazyLoad('covidTravel/CovidTravel') },
    { path: '/weather/*', name: 'WeatherSpec', component: lazyLoad('weather/Weather') },
    { path: '/weather*', name: 'Weather', component: lazyLoad('weather/Weather') },
    { path: '/absolute*', name: 'AbsoluteRadio', component: lazyLoad('absoluteRadio/absoluteRadio') },
    { path: '/absolute/*', name: 'AbsoluteRadioSpec', component: lazyLoad('absoluteRadio/absoluteRadio') },
    { path: '/booker*', name: 'Booker', component: lazyLoad('booker/Booker')},
    { path: '/booker/*', name: 'BookerSpec', component: lazyLoad('booker/Booker')},
    { path: '/d3*', name: 'D3', component: lazyLoad('d3/D3') },
    { path: '/watcher*', name: 'Watcher', component: lazyLoad('watcher/Watcher') },
    { path: '/watcher/*', name: 'WatcherSpec', component: lazyLoad('watcher/Watcher') },
    { path: '/loremen*', name: 'Loremen', component: lazyLoad('loremen/Loremen')},
    { path: '/loremen/*', name: 'LoremenSpec', component: lazyLoad('loremen/Loremen')},
    { path: '/eurovision*', name: 'EurovisionScorer', component: lazyLoad('eurovision/Eurovision')},
    { path: '/summer', name: 'Summer', component: lazyLoad('summerLeague/SummerLeague') },
    { path: '/summer/*', name: 'Summer', component: lazyLoad('summerLeague/SummerLeague') },
    { path: '*', name: 'FrontPage', component: lazyLoad('FrontPage') },
]



const router = new VueRouter({
    routes: routes,
    mode: 'history',
});

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueRouter);

Vue.component('country-flag', CountryFlag)

Vue.use(new VueSocketIO({
    connection: 'https://myk.ninja:443',
}));

Vue.use(VueCookies);
Vue.$cookies.config(60 * 60 * 3);

new Vue({
  router,
  render: h => h(Basic),
  store,
}).$mount('#app')
