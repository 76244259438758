<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: "basic",
  data() {
    return {};
  },
  methods: {
  },
  computed: {
  },
};
</script>

<style>

body.front-page {
  background-color: black;
}

body.absolute-radio {
  overflow: hidden;
}

body.booker {
  overflow: hidden;
  background-color: #8c566b;
}

body.d3 {
  background-color: #000600;
}

body.date {
  background-color: #eaead0;
}

body.deathlist {
  background-color: #23233f;
}

body.eurovision {
  background-color: black;
  overflow: hidden;
}

body.jeopardy {
  overflow: scroll;
  background-color: purple
}

body.loremen {
  overflow: hidden;
  background-color: #c2b07f;
}

body.quiz {
  overflow: hidden;
}

body.qll {
  background-color: black;
}

body.covid19 {
  overflow: hidden;
  background-color: black;
}

body.watcher {
  overflow: hidden;
  background-color: #c9ffc9;
}

body.weather {
  overflow: hidden;
  background-color: black;
}

</style>
